<template>
  <div>
    <b-modal
      ref="modal-lab-order-kits" scrollable no-close-on-backdrop
      hide-footer hide-header size="lg" centered>
      <div>
        <div class="mobile-select-kit-header">
          <h4>Select kit</h4>
          <span @click="hiddenModal">
            <circle-x-icon />
          </span>
        </div>
        <div class="desc">
          The check mark indicates the currently selected kit
        </div>

        <div class="kits-body cs-scroll-style" v-if="pageCode === 'G25-DASHBOARD' || pageCode === 'G25'">
          <div class="mobile-kit-item"
            v-for="(resultItem)  in $store.getters['orderResult/getOrderResults']" 
            :key="resultItem.id" @click="onChangeResultItem(resultItem)">
            <label class="lab-checkbox-container">
              <span class="label">{{resultItem.resultTitle}}</span>
              <input type="checkbox" v-if="resultItem.id === selectedOrderResultId" checked />
              <input type="checkbox" v-if="resultItem.id !== selectedOrderResultId" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>

        <div class="kits-body cs-scroll-style" v-if="pageCode === 'TRAIT-HEALTH'">
          <div class="mobile-kit-item" v-for="(resultItem)  in orderResultsTrait" 
            :key="resultItem.id" @click="onChangeResultTraitItem(resultItem)">
            <label class="lab-checkbox-container">
              <span class="label">{{resultItem.resultTitle}}</span>
              <input type="checkbox" v-if="resultItem.id === selectedOrderResultTraitId" checked />
              <input type="checkbox" v-if="resultItem.id !== selectedOrderResultTraitId" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CircleXIcon from '@/layouts/icons/CircleXIcon.vue';

export default {
  components: {
    CircleXIcon,
  },
  props: {
    modalObject: {
      type: Object,
    },
  },
  data() {
    return {
      isShowDropdownMenu: false,
    }
  },
  methods: {
    onChangeResultItem(item) {
      this.$store.commit('orderResult/setTargetData', item);
      this.hiddenModal();
    },
    onChangeResultTraitItem(item) {
      this.$store.commit('orderResultTrait/setTargetData', item);
      this.hiddenModal();
    },

    initialModal() {
      this.$refs['modal-lab-order-kits'].show();
    },
    hiddenModal() {
      this.$refs['modal-lab-order-kits'].hide()
      this.modalObject.showModal = false;
    },
  },
  computed: {
    orderResults() {
      return this.$store.getters['orderResult/getOrderResults'];
    },
    selectedOrderResultId() {
      return this.$store.getters['orderResult/getSelectedOrderResultId'];
    },

    orderResultsTrait() {
      return this.$store.getters['orderResultTrait/getOrderResults'];
    },
    selectedOrderResultTraitId() {
      return this.$store.getters['orderResultTrait/getSelectedOrderResultId'];
    },

    pageCode() {
      return this.$route.meta.code;
    },
  },
  mounted() {
    this.initialModal()
  },
}
</script>

<style lang="scss" scoped>
.mobile-select-kit-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: var(--lab-black);
    font-size: 1.2rem;
  }
}

.desc {
  font-size: 0.85rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.kits-body {
  max-height: 300px;
  overflow: scroll;
  color: var(--lab-stieglitz-silver);
}
.mobile-kit-item {
  padding: 0.8rem 0;
  .label {
    font-size: 1rem;
  }
}

.dark-layout {
  .mobile-select-kit-header h4 {
    color: var(--lab-lago-blue-color);
  }
}
</style>
