<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 29.8 32.83">
    <defs>
    </defs>
    <g id="Layer_2-2" data-name="Layer 2">
      <g>
        <path class="cls-1" d="M5.91,8.14C6.37,3.52,10.26,0,14.9,0s8.53,3.52,8.99,8.14l.47,4.69c.03,.28,.04,.43,.06,.57,.24,1.93,.9,3.79,1.95,5.44,.08,.12,.16,.24,.31,.48l1.41,2.11c1.29,1.93,1.93,2.89,1.65,3.67-.05,.15-.13,.29-.22,.41-.49,.67-1.65,.67-3.97,.67H4.25c-2.32,0-3.48,0-3.97-.67-.09-.13-.17-.26-.22-.41-.28-.78,.36-1.74,1.65-3.67l1.41-2.11c.16-.24,.24-.36,.31-.48,1.05-1.64,1.71-3.5,1.95-5.44,.02-.14,.03-.28,.06-.57l.47-4.69Z"/>
        <path class="cls-1" d="M14.9,32.83c-.84,0-1.64-.07-2.32-.2-.94-.18-1.59-.46-2.04-.88-.71-.67-.75-1.78-.09-2.5,.62-.67,1.64-.74,2.36-.2,.06,.02,.2,.07,.44,.11,.46,.09,1.05,.14,1.66,.14s1.2-.05,1.66-.14c.24-.05,.37-.09,.44-.11,.71-.54,1.73-.46,2.36,.2,.67,.71,.63,1.83-.09,2.5-.45,.42-1.1,.7-2.04,.88-.68,.13-1.49,.2-2.32,.2Zm-1.96-3.67s0,0,0,0c0,0,0,0,0,0Zm3.92,0s0,0,0,0c0,0,0,0,0,0Zm.18-.13h0s0,0,0,0Zm-4.27,0h0s0,0,0,0Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 25px;
}  

.cls-1 {
  fill: #222;
}

.dark-layout {
  .cls-1 {
    fill: #1d2328;
  }
}

@media screen and (max-width: 769px) {
  .dark-layout {
    .cls-1 {
      fill: var(--lab-neverything);
    }
  }
}
</style>
