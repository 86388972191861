<template>
    <div class="mobile-lab-sidebar" :class="{ 'hide' : !$store.state.app.isOpenMobileNavbar }"  ref="mobile_lab_sidebar">
      <div class="mobile-lab-sidebar-header">
        <lab-notification-dropdown />

        <div class="p-1">
          <lab-user-dropdown />
          <hr />
        </div>
      </div>

      <div class="mobile-lab-sidebar-menu">
        <div class="mobile-lab-sidebar-menu-item" @click="profileSettings()">
          <span class="icon">
            <img :src="'https://api.illustrativedna.com/assets/icons/'+$store.state.appConfig.layout.skin+'/profile_settings.svg'">
          </span>
          <span class="text">
            Profile Settings
          </span>
        </div>
        <div class="mobile-lab-sidebar-menu-item" @click="logout()">
          <span class="icon">
            <img :src="'https://api.illustrativedna.com/assets/icons/'+$store.state.appConfig.layout.skin+'/logout.svg'">
          </span>
          <span class="text">
            Log out
          </span>
        </div>
      </div>

      <div class="mobile-lab-sidebar-footer">
        <lab-dark-toggler />
      </div>
    </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import LabNotificationDropdown from '../nav-bar-components/LabNotificationDropdown.vue'
import LabUserDropdown from '../nav-bar-components/LabUserDropdown.vue'
import LabDarkToggler from '../nav-bar-components/LabDarkToggler.vue'

export default {
  components: { 
    LabNotificationDropdown,
    LabUserDropdown,
    LabDarkToggler,
  },
  props: {
  },
  data() {
    return {
    }
  },  
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const sidebar_element = this.$refs.mobile_lab_sidebar;
        const profile_element = this.$parent.$refs.mobile_lab_bottom_navbar.$refs.mobile_profile;
        const target = e.target;

        if(profile_element.contains(target)) {
          this.$store.state.app.isOpenMobileNavbar = !this.$store.state.app.isOpenMobileNavbar;
        } else {
          if (sidebar_element !== target && !sidebar_element.contains(target)) {
            this.$store.state.app.isOpenMobileNavbar = false;
          }
        }
        
      } catch (error) {}
    },
    profileSettings() {
      this.$store.state.app.isOpenMobileNavbar = false;
      this.$router.push({ name: 'profile-settings' });
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      this.$store.state.app.isOpenMobileNavbar = false;
      // Redirect to login page
      this.$router.push({ name: 'login' })
    },
  },
  computed: {
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
}
</script>

<style lang="scss">
.mobile-lab-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 230px;
  padding: 10px 10px;
  background: var(--sidebar-bg-color);
  transition: var(--lab-trans-05);
  z-index: 1002;
  text-align: center;
}

.mobile-lab-sidebar.hide {
  margin-left: -230px;
}

.mobile-lab-sidebar-menu {
  padding: 0px 10px;
}

.mobile-lab-sidebar-menu .mobile-lab-sidebar-menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px 5px;
  border-radius: 5px;
  transition: var(--lab-trans-03);
  margin-bottom: 10px;
}

.mobile-lab-sidebar-menu .mobile-lab-sidebar-menu-item:hover {
  background-color: var(--bg-card-hover-color);
}

.mobile-lab-sidebar-menu img {
  width: 30px;
  height: 30px;
}

.mobile-lab-sidebar-menu .text {
  margin-left: 10px;
  color: var(--lab-carbon-fiber);
  font-size: 1rem;
  font-weight: 600;
}

.mobile-lab-sidebar-footer {
  position: relative;
  top: 55%;
  text-align: left;
  li {
    list-style-type: none;
  }
}

.dark-layout {
  .mobile-lab-sidebar-menu .text {
    color: var(--lab-lago-blue-color);
  }
}
</style>
