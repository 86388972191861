import { render, staticRenderFns } from "./LabNotificationDropdown.vue?vue&type=template&id=5fbacf60&scoped=true&"
import script from "./LabNotificationDropdown.vue?vue&type=script&lang=js&"
export * from "./LabNotificationDropdown.vue?vue&type=script&lang=js&"
import style0 from "./LabNotificationDropdown.vue?vue&type=style&index=0&id=5fbacf60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fbacf60",
  null
  
)

export default component.exports