<template>
  <div class="lab-layout">
    <lab-side-bar class="lab-layout-sidebar" v-if="isUserLoggedIn"/>
    <mobile-lab-header class="lab-layout-mobile-header" v-if="isUserLoggedIn"/>
   
    <section class="home">
      <lab-header class="lab-layout-header" v-if="isUserLoggedIn"/> 
      <div class="body">
        <router-view class="router-main"/>
      </div>
    </section>

    <mobile-lab-bottom-navbar class="lab-layout-mobile" ref="mobile_lab_bottom_navbar" v-if="isUserLoggedIn"/>
    <mobile-lab-sidebar class="lab-layout-mobile" v-if="isUserLoggedIn"/>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import LabSideBar from '../components/LabSideBar.vue';
import LabHeader from '../components/LabHeader.vue';
import MobileLabHeader from '../components/mobile-layouts/MobileLabHeader.vue';
import MobileLabBottomNavbar from '../components/mobile-layouts/MobileLabBottomNavbar.vue';
import MobileLabSidebar from '../components/mobile-layouts/MobileLabSidebar.vue';

export default {
  components: {
    LabSideBar,
    LabHeader,
    MobileLabHeader,
    MobileLabBottomNavbar,
    MobileLabSidebar,
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters['account/getIsLoggedIn'];
    },
  },
  setup() {
    const {
      skin,
      navbarType,
      routerTransition,
      isNavMenuHidden,
      contentWidth,
    } = useAppConfig()

    return {
      skin,
      navbarType,
      isNavMenuHidden,
      routerTransition,
      contentWidth,
    }
  },
}
</script>

<style lang="scss">
.lab-layout {
  position: relative;
  height: 100vh;
}

.home {
  position: relative;
  left: 230px;
  height: 100vh;
  width: calc(100% - 250px);
  transition: var(--lab-trans-05);
  padding: 0 20px;
}

.home .body {
  position: relative;
  top: 95px;
  margin-bottom: 10px;
  padding: 30px;
  margin: auto;
  width: 85%;
}

.sidebar.hidden-menu-expand ~ .home {
  left: 90px;
  width: calc(100% - 90px);
}

.sidebar.hidden-menu-expand ~ .home .lab-header {
  width: calc(100% - 105px) !important;
}

@media screen and (max-width: 769px) {
  .lab-layout-sidebar, .lab-layout-header {
    display: none !important;
  }

  .home {
    left: 0 !important;
    width: 100% !important;
    padding: 0;
  }

  .router-main {
    margin-bottom: 100px !important;
  }

  .home .body {
    top: 65px;
  }
}

@media screen and (min-width: 769px) {
  .lab-layout-mobile-header, .lab-layout-mobile {
    display: none !important;
  }
}

@media screen and (max-width: 1550px) {
  .home .body {
    margin-bottom: 10px;
    padding: 25px;
    margin: auto;
    width: 95%;
  }
}

@media screen and (max-width: 1400px) {
  .home .body {
    margin-bottom: 10px;
    padding: 20px;
    margin: auto;
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .home .body {
    margin-bottom: 10px;
    padding: 15px;
    margin: auto;
    width: 100%;
  }
}

</style>
