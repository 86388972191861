<template>
  <div class="lab-dropdown">
    <div class="notification-dropdown" @click="isShowDropdownMenu = !isShowDropdownMenu" ref="dropdown" >
      <bell-icon v-if="notificationCount === 0"/>
      <div class="notification-avatar" v-if="notificationCount > 0">
        <div class="circle"></div>
        <bell-icon v-if="true"/>
      </div>
    </div>
    
    <div class="lab-dropdown-content" :class="{ 'show': isShowDropdownMenu}">
      <div class="notification-dropdown-menu">
        <h5 v-if="notificationCount > 0">An Update Published</h5>
        <a @click="$router.push({name: 'notifications'})">Click to see update logs</a>
      </div>
    </div>
  </div>
</template>

<script>
import BellIcon from '../../icons/BellIcon.vue';

export default {
  components: {
    BellIcon,
  },
  data() {
    return {
      isShowDropdownMenu: false,
    }
  },
  computed: {
    notificationCount() {
      return this.$store.getters['notification/getNotificationCount'];
    },
    generalNotifications() {
      return this.$store.getters['notification/getNotifications'];
    },
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdown;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownMenu = false;
        }
      } catch (error) {}
    },
    fetchNotifications() {
      this.$store.dispatch('notification/fetchNotifications', {});
    },

    readNotifications() {
      const notificationIds = this.generalNotifications.map(x => x.id);
      this.$store.dispatch('notification/readNotifications', notificationIds)
        .then(() => {
          this.fetchNotifications();
        });
    },

    onClickNotification(notification) {
      if (notification.notificationType === 1) {
        this.$router.push({ name: 'notification-all' });
      } else if (notification.notificationType === 3 || notification.notificationType === 4) {
        this.$router.push({ name: 'dashboard' });
      } else if (notification.notificationType === 2) {
        this.$router.push({ name: 'ticket-detail', params: { id: notification.code } })
      }
    },
  },
  created() {
    this.fetchNotifications();
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  }
}
</script>

<style lang="scss" scoped>
.notification-dropdown {
  cursor: pointer;
}
.lab-dropdown-content {
  width: 200px;
  left: -361%;
  margin-top: 10px;

  a {
    font-size: 0.8rem;
    color: var(--lab-dust-to-dust);
  }
}

.notification-avatar {
  cursor: pointer;
  position: relative;
  padding: 5px;
  border-radius: 5px;

  .circle {
    position: absolute;
    border-radius: 50%;
    height: 7px;
    width: 7px;
    top: -5px;
    right: 13px;
    background-color: var(--lab-fight-sunrise-color);
  }
}

@media screen and (max-width: 769px) {
  .notification-avatar  {
    width: 33px;
  }
  .notification-dropdown {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
