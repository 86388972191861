<template>
  <div class="lab-dropdown mr-1" v-if="$store.getters['orderResultTrait/getOrderResults'].length">
    <div class="lab-dropdown-btn" @click="isShowDropdownMenu = !isShowDropdownMenu" ref="dropdown">
      <span class="lab-dropdown-btn-value">{{ orderResults[selectedOrderResultIndex].resultTitle}}</span>
      <span class="lab-dropdown-btn-icon">
        <img :src="'https://api.illustrativedna.com/assets/icons/'+$store.state.appConfig.layout.skin+'/dropdown.svg'">
      </span>
    </div>
    <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdownMenu}">
      <ul>
        <li v-for="(resultItem, index)  in $store.getters['orderResultTrait/getOrderResults']"
            :key="resultItem.id"
            @click="onChangeResultItem(resultItem, index)">
          {{ resultItem.resultTitle }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import AlertService from '@/common/alert.service'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedOrderResultIndex: 0,
      isShowDropdownMenu: false,
    }
  },
  methods: {
    onChangeResultItem(item, index) {
      this.selectedOrderResultIndex = index;
      this.$store.commit('orderResultTrait/setTargetData', item);
    },
    fetchOrderResults() {
      this.$store.dispatch('orderResultTrait/fetchOrderResults').then(response => {
        if (response.statusCode === 200 && response.result.length > 0) {
          this.$store.commit('orderResultTrait/setOrderResults', response.result);
          if (response.result.length > 0) {
            this.$store.commit('orderResultTrait/setTargetData', response.result[0]);
          }
        } else if (response.statusCode !== 200) {
          AlertService.error(this, response.message)
        }
      });
    },

    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdown;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownMenu = false;
        }
      } catch (error) {}
    },
  },
  computed: {
    orderResults() {
      return this.$store.getters['orderResultTrait/getOrderResults'];
    },
  },
  created() {
    this.fetchOrderResults();
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
}
</script>

<style lang="scss" scoped>
.kit-title {
  color: #f1597d !important;
  font-size: 16px;
  font-weight: bold;
}
.kit-desc {
  color: #898989 !important;
}

.dark-layout .lab-dropdown .lab-dropdown-btn {
  color: var(--lab-lago-blue-color);
  border: 2px solid var(--lab-lago-blue-color);
}
</style>
