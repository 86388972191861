<template>
  <b-nav-item @click="onChangeToggle()">
    <label class="lab-switch">
      <input type="checkbox" v-model="isDark">
      <span class="slider round"></span>
    </label>
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  components: {
  },
  methods: {
    onChangeToggle() {
      this.skin = this.isDark ? 'light' : 'dark';
      
      this.$root.$emit('on-change-skintoggle', this.skin);
    },
  },
  setup() {
    const { skin } = useAppConfig()
  
    const isDark = computed(() => skin.value === 'dark')
    return { skin, isDark }
  },
}
</script>

<style lang="scss" scoped>
.lab-switch .slider {
 background-color: var(--lab-night-sky);
}

.lab-switch input:checked + .slider {
  background-color: var(--lab-night-sky);
}
</style>
